import React, { FC, useEffect, useState } from 'react';
import {
  useBi,
  useEnvironment,
  useTranslation,
  type WidgetProps,
} from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';
import {
  Channel,
  Type,
} from '@wix/ambassador-notifications-v1-notification-preferences/types';
import { membersAreaNotificationSettingsPageFinishLoading } from '@wix/bi-logger-identity-data/v2';
import { st, classes } from './Widget.st.css';
import { SubscriptionStatus } from './components/subscription-status';
import { PreferencesList } from './components/preferences-list';
import type { ChannelPreferences } from '../../../types';

export type ControllerProps = {
  startLoadingTime: number;
  isSubscribed: boolean;
  onChangeSubscription(isSubscribed: boolean): void;
  channelPreferences?: ChannelPreferences;
  onChangePreference(id: string, mute: boolean): void;
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  useEffect(() => {
    if (props.startLoadingTime) {
      const loading_time = Number(new Date()) - props.startLoadingTime;
      bi.report(
        membersAreaNotificationSettingsPageFinishLoading({
          loading_time,
        }),
      );
    }
  }, [bi, props.startLoadingTime]);

  const [isSubscribed, setIsSubscribed] = useState(props.isSubscribed);

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook="PreferencesOoi-wrapper"
    >
      <div>
        <Text tagName="H2" className={st(classes.titleText, classes.title)}>
          {t('app.widget.title')}
        </Text>
        <Text tagName="H5" className={st(classes.plainText, classes.subtitle)}>
          {t('app.widget.subtitle')}
        </Text>
      </div>
      <PreferencesList
        preferencesByApp={props.channelPreferences?.preferencesByApp}
        type={Type.PROMOTIONAL}
        onChangeItem={props.onChangePreference}
        channel={Channel.EMAIL}
      >
        <SubscriptionStatus
          isSubscribed={isSubscribed}
          onClick={() => {
            setIsSubscribed(!isSubscribed);
            props.onChangeSubscription(!isSubscribed);
          }}
        />
      </PreferencesList>
      <PreferencesList
        preferencesByApp={props.channelPreferences?.preferencesByApp}
        type={Type.TRANSACTIONAL}
        onChangeItem={props.onChangePreference}
        channel={Channel.EMAIL}
      />
    </div>
  );
};
