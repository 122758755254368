import React, { FC } from 'react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { membersAreaSettingsClickOnToggle } from '@wix/bi-logger-identity-data/v2';
import { Button, ButtonPriority, ButtonSize, Text } from 'wix-ui-tpa/cssVars';
import { st, classes } from '../../Widget.st.css';
import { Channel } from '@wix/ambassador-notifications-v1-notification-preferences/types';

interface Props {
  isSubscribed: boolean;
  onClick(): void;
}

export const SubscriptionStatus: FC<Props> = ({ isSubscribed, onClick }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  return (
    <div className={classes.subscriptionStatusContainer}>
      <Text className={st(classes.plainText, classes.subscriptionTitle)}>
        {t('app.widget.marketing-emails.subscription.title')}
      </Text>
      <Button
        upgrade
        size={isMobile ? ButtonSize.small : ButtonSize.medium}
        className={
          isSubscribed ? classes.secondaryButton : classes.primaryButton
        }
        priority={
          isSubscribed ? ButtonPriority.secondary : ButtonPriority.primary
        }
        onClick={() => {
          bi.report(
            membersAreaSettingsClickOnToggle({
              title: 'subscription',
              toggleName: 'subscribe_button',
              description: 'Receive marketing emails',
              toggle_state: !isSubscribed,
              channel: Channel.EMAIL,
            }),
          );
          onClick();
        }}
      >
        {t(
          isSubscribed
            ? 'app.widget.marketing-emails.subscription.unsubscribe'
            : 'app.widget.marketing-emails.subscription.subscribe',
        )}
      </Button>
    </div>
  );
};
