import React, { FC, useState } from 'react';
import { Divider, Text, ToggleSwitch } from 'wix-ui-tpa/cssVars';
import { st, classes } from '../../Widget.st.css';
import type { Preference } from '../../../../../types';

interface Props {
  preference?: Preference;
  onClick(mute: boolean): void;
}

export const PreferenceItem: FC<Props> = ({ preference, onClick }) => {
  const [checked, setChecked] = useState(!preference?.isMuted);

  const onChange = () => {
    setChecked(!checked);
    onClick(checked);
  };

  return preference ? (
    <>
      <div className={classes.preferenceContainer}>
        <Text className={st(classes.plainText, classes.preference)}>
          {preference.displayName}
        </Text>
        <ToggleSwitch checked={checked} onChange={onChange} />
      </div>
      <div className={classes.preferenceDividerContainer}>
        <Divider />
      </div>
    </>
  ) : null;
};
